// src/Privacy.tsx
import React from 'react';
import './styles/theme.css';

const Privacy: React.FC = () => {
  return (
    <section className="privacy">
      <h1>Privacy Policy & Account Management</h1>
      <p><strong>Last Updated:</strong> 11 October 2024</p>

      <h3>Introduction</h3>
      <p>
        Venkateswara Shopping Mall ("us", "we", or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information in connection with our website (vsmall.in) and our Android application ("VSM Partners").
      </p>

      <h3>Information We Collect</h3>
      <p>
        <strong>Website:</strong> We do not collect any personal information from visitors to our website.
      </p>
      <p>
        <strong>App (Employee Accounts Only):</strong> We collect the following information from employees who create an account in our App:
      </p>
      <ul>
        <li>Name</li>
        <li>Username</li>
        <li>Email Address</li>
        <li>Password (stored securely using hashing)</li>
      </ul>

      <h3>How We Use Your Information</h3>
      <p>
        <strong>App:</strong> The information we collect from employee accounts is used solely for the purpose of authentication and access to the App's features. We do not use this information for any other purpose.
      </p>

      <h3>Disclosure of Your Information</h3>
      <p>
        We do not share, sell, rent, or otherwise disclose your personal information to any third parties.
      </p>

      <h3>Data Security</h3>
      <p>
        We use industry-standard security measures to protect your information, both during transmission and once we receive it. These measures include:
      </p>
      <ul>
        <li><strong>Password Hashing:</strong> Employee account passwords are stored securely using one-way hashing, which means even we cannot see your original password.</li>
        <li><strong>Secure Transmission:</strong> Data transmitted between the App and our servers is encrypted using HTTPS.</li>
      </ul>

      <h3>Your Data Rights</h3>
      <p>
        <strong>Account Deletion:</strong> You have the right to delete your employee account at any time. To do so, please follow these steps:
      </p>
      <ol>
        <li>Log in to your account in the App.</li>
        <li>Go to the "Account Settings" section.</li>
        <li>Select the "Delete Account" option.</li>
        <li>Confirm your deletion request.</li>
      </ol>
      <p>
        Please note that once you delete your account, your data will be permanently removed from our systems.
      </p>

      <h3>Changes to This Privacy Policy</h3>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us at: krischatvsm@gmail.com
      </p>
    </section>
  );
}

export default Privacy;