// src/About.tsx
import React from 'react';
import './styles/theme.css'; // Import the CSS file

const About: React.FC = () => {
  return (
    <section className="about-section">
      <div className="about-content">
        <h1 className="about-header">About Venkateshwara Shopping Mall</h1>
        <h3>Where Tradition Meets Trend</h3>
        <p>
          At Venkateshwara Shopping Mall, we believe fashion is more than just clothing—it's an expression of who you are. We combine tradition with trend to offer an immersive shopping experience that caters to modern sensibilities while respecting heritage. Whether you're looking for the latest runway-inspired designs or timeless classics, we are here to make every shopping moment special.
        </p>

        <h3>A Shopping Experience Like No Other</h3>
        <p>
          Step into a world where quality meets variety. Our shelves boast an extensive collection of fashion for men, women, and children across multiple brands. From sleek office attire to festive wear that captures the essence of celebration, we are committed to helping you express your unique style, whatever the occasion.
        </p>
        <p>
          At Venkateshwara Shopping Mall, shopping is not just about purchasing clothes—it's about discovering the best version of yourself. Our store layout is designed for easy navigation and stress-free browsing, ensuring you enjoy every moment spent with us.
        </p>

        <h3>Our Mission: Style for Everyone</h3>
        <p>
          We believe fashion should be inclusive, accessible, and empowering. That's why we’ve curated a selection that speaks to different tastes, budgets, and body types. Our expert staff is always ready to assist you with personalized advice, ensuring you leave our store feeling confident and satisfied. We are passionate about helping you find pieces that not only complement your wardrobe but also enhance your personality.
        </p>
        <p>
          Venkateshwara Shopping Mall isn’t just a place to shop—it’s a destination to experience style, elegance, and unparalleled service.
        </p>

        <div className="visit-us">
          <h3>Visit Us</h3>
          <p>
            We invite you to visit us and experience the best of fashion under one roof.
          </p>
          <address>
            <strong>Venkateshwara Shopping Mall</strong><br/>
            Subhash Gunj, Zaheerabad, Telangana 502220<br/>
            Phone: <a href="tel:+918451283939">+91-8451-283939</a>
          </address>
          <address>
            <strong>Venkateshwara Shopping Mall</strong><br/>
            G.K Towers, Udgir Road<br/>
            Bidar<br/>
            Phone: <a href="tel:+919581800039">+919581800039</a>
          </address>
          <address>
            <strong>Venkateshwara Shopping Mall</strong><br/>
            D.No : 1-9-165/12/1,<br/>
            Basweshwara Chowk , Karasgutti Road, Narayankhed<br/>
            Sangareddy, Telangana 502286<br/>
            Phone: <a href="tel:+918885844439">+91 8885844439</a>
          </address>
          <address>
            <strong>Venkateshwara Shopping Mall</strong><br/>
            Bhavani Mandir Road<br/>Chitguppa<br/>
            Phone: <a href="tel:+917659035939">+91 7659035939</a>
          </address>
        </div>
      </div>
    </section>
  );
};

export default About;