import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import About from './About';
import Collections from './Collections';
import Account from './Account';
import Privacy from './Privacy';
import './styles/theme.css'; // Import your CSS file

function App() {
  return (
    <Router>
      <div className="app-container">
        <header>
          <div className="header-container"> {/* Add a container for styling */}
            <img src="/logo.png" alt="Venkateswara Shopping Mall" className="logo"/>
            <nav>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/collections">Collections</Link></li>
                <li><Link to="/account">My Account</Link></li>
                <li><Link to="/privacy">Privacy</Link></li>
              </ul>
            </nav>
          </div>
        </header>

        <main> {/* Wrap your main content in a <main> tag */}
          <Routes>
            <Route path="/about" element={<About/>}/>
            <Route path="/collections" element={<Collections/>}/>
            <Route path="/account" element={<Account/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/" element={
              <section className="home-section">
                <h2 className="home-header">Welcome to Venkateshwara Shopping Mall</h2>
                <div className="home-content">
                  <div className="home-image">
                    <img src="/home-test.png" alt="Stylish Clothing" />
                  </div>
                  <div className="home-text">
                    <h2>Discover Your Style</h2>
                    <p>
                      At Venkateshwara Shopping Mall, we believe that fashion is a form of self-expression. That's why we offer a curated selection of clothing and accessories from top brands, designed to help you look and feel your best.
                    </p>
                    <p>
                      Whether you're searching for the perfect outfit for a special occasion, updating your everyday wardrobe, or simply looking for inspiration, our friendly and knowledgeable staff are here to assist you.
                    </p>
                    <Link to="/collections" className="cta-button">Explore Our Collections</Link>
                  </div>
                </div>
              </section>
            }/>
          </Routes>
        </main>

        <footer>
          <p>&copy; {new Date().getFullYear()} Venkateswara Shopping Mall. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;