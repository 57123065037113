import React, { useState, useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS
import './styles/theme.css'; // Create this CSS file for styling

const Collections: React.FC = () => {
  const categories = useMemo(() => [
    { name: 'Ethnic Wear', images: ['/ethnic1.jpg', '/ethnic2.jpg', '/ethnic3.jpg', '/ethnic4.jpg'] },
    { name: "Men's Wear", images: ['/men1.jpg', '/men2.jpg'] },
    { name: "Women's Wear", images: ['/women1.jpg', '/women2.jpg', '/women3.jpg'] },
    { name: 'Kids Wear', images: ['/kids1.jpg', '/kids2.jpg'] },
  ], []);

  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    categories.length > 0 ? categories[0].name : null
  );

  const handleCategoryClick = (categoryName: string) => {
    setSelectedCategory(prevCategory =>
      prevCategory === categoryName ? null : categoryName
    );
  };

  return (
    <section className="collections">
      <h2>Our Collections</h2>

      <div className="collections-container">
        <aside className="categories">
          <h3>Categories</h3>
          <ul>
            {categories.map(category => (
              <li
                key={category.name}
                onClick={() => handleCategoryClick(category.name)}
                className={selectedCategory === category.name ? 'active' : ''}
              >
                {category.name}
              </li>
            ))}
          </ul>
        </aside>

        <div className="carousel-container">
          {selectedCategory && (
            <Carousel autoPlay infiniteLoop showThumbs={false}>
              {categories
                .find(cat => cat.name === selectedCategory)
                ?.images.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`${selectedCategory} - ${index + 1}`} />
                  </div>
                ))}
            </Carousel>
          )}
        </div>
      </div>
    </section>
  );
};

export default Collections;