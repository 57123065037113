import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import { FaGoogle } from 'react-icons/fa'; // Google icon from react-icons

const Account: React.FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');

  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;

    try {
      const response = await fetch('/auth/basic-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      });

      if (!response.ok) throw new Error('Login failed. Please check your credentials.');

      const data = await response.json();
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);
      setEmail(username);

      alert('Login successful.');
    } catch (error: any) {
      alert(error.message);
    }
  };

  const deleteAccount = async () => {
    if (!accessToken) {
      alert('Access token is missing.');
      return;
    }

    try {
      const response = await fetch(`/api/users?username=${encodeURIComponent(email)}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        alert('Account deleted successfully.');
        window.location.href = '/';
      } else if (response.status === 401 && refreshToken) {
        await refreshAccessTokenAndRetry();
      } else if (response.status === 404) {
        const message = await response.text();
        alert('Error: ' + message);
      } else {
        alert('Failed to delete account. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const refreshAccessTokenAndRetry = async () => {
    if (!refreshToken) {
      alert('No refresh token available. Please login again.');
      return;
    }

    try {
      const response = await fetch('/auth/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      });

      const data = await response.json();

      if (data.accessToken) {
        setAccessToken(data.accessToken);
        await deleteAccount();
      } else {
        alert('Failed to refresh token. Please login again.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="account-section login-page">
      <div id="header"></div>
      <div className="container">
        {!accessToken ? (
          <>
            <h1 className="section-title">Login</h1>
            <p className="instruction-text">Please enter your credentials to log in.</p>

            <form id="basic-login-form" className="form" onSubmit={handleLoginSubmit}>
              <div className="input-group">
                <label>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    className="form-input"
                    required
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="form-input"
                    required
                  />
                </label>
              </div>
              <button type="submit" className="cta-button">Login</button>
            </form>

            <div className="login-buttons">
              <p className="or-text">Or</p>
              <form action="/auth/google/login" method="GET" className="google-login-form">
                <button className="google-login-button" type="submit">
                  <FaGoogle className="google-icon" /> Login with Google
                </button>
              </form>
            </div>
          </>
        ) : (
          <>
            <h1 className="section-title">Delete Your Account</h1>
            <p className="instruction-text">Are you sure you want to delete your account?</p>
            <form id="delete-form" className="form">
              <input type="hidden" id="access-token" value={accessToken || ''} />
              <input type="hidden" id="email" value={email || ''} />
              <button type="button" className="cta-button delete-button" onClick={deleteAccount}>
                Delete Account
              </button>
            </form>
          </>
        )}
      </div>
      <div id="footer"></div>
    </div>
  );
};

export default Account;
